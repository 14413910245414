






import { Component, Vue } from 'vue-property-decorator'
import { EventBus } from '@/utils/event-bus'

@Component
export default class SSO extends Vue {
  headerMsg: string = 'Redirecting to login...'
  mounted () {
    const _browserDetect = (this as any).$browserDetect
    if (_browserDetect.isIE && _browserDetect.version === '11' && this.$store.getters.isAuthenticated) {
      this.headerMsg = 'Browser not supported.'
      const _msgNode = this.$createElement('div', [
        this.$createElement('p', { class: ['mb-1'] }, ['Unforunately this browser is not supported by the Value Platform.']),
        this.$createElement('p', { class: ['mb-1'] }, ['Please use an alternative browser.']),
        this.$createElement('p', { class: ['m-0'] }, ['For the best experience we recommend Google Chrome.'])
      ])
      this.$bvModal.msgBoxOk([_msgNode], {
        size: 'sm',
        buttonSize: 'md',
        okTitle: 'OK',
        titleClass: 'w-100 text-center',
        okVariant: 'default',
        footerClass: 'p-2 text-center',
        bodyClass: 'text-center',
        noCloseOnBackdrop: true,
        noCloseOnEscape: true,
        hideHeaderClose: true,
        title: 'Browser not supported',
        centered: true
      }).then(() => {
        this.$router.push({ name: 'home' })
      })
    } else {
      EventBus.$emit('show-loader')
      // axios.get('/authenticate', { withCredentials: false, baseURL: `${process.env.VUE_APP_API_HOST || 'http://localhost:3305'}/api/v1/` })
      const form = document.createElement('form')
      form.method = 'post'
      form.action = (`${process.env.VUE_APP_API_HOST || 'http://localhost:3305'}/api/v1/`) + 'authenticate'

      document.body.appendChild(form)
      form.submit()
    }
  }
}
